import { apiResponseSchema } from 'schemas/api-response/apiResponse';
import { optionalWithNull } from 'schemas/generic/optional-with-null';
import { z } from 'zod';

export enum WaitingListEntryStatus {
  Active = 'ACTIVE',
  Passive = 'PASSIVE',
  Invitation = 'INVITATION',
  Expired = 'EXPIRED',
}

export const WaitingListEntryStatusEnum = z.nativeEnum(WaitingListEntryStatus);

export const waitingListEntryForUserSchema = z.object({
  id: z.string(),
  propertyGroupId: z.string(),
  propertyGroupName: z.string(),
  address: z.string(),
  city: z.string(),
  sizeMin: optionalWithNull(z.number()),
  sizeMax: optionalWithNull(z.number()),
  roomsMin: optionalWithNull(z.number()),
  roomsMax: optionalWithNull(z.number()),
  priceMin: optionalWithNull(z.number()),
  priceMax: optionalWithNull(z.number()),
  membershipId: optionalWithNull(z.string()),
  seniority: z.string(),
  addedOn: z.string(),
  updatedOn: z.string(),
  status: WaitingListEntryStatusEnum,
  propertyGroupImages: z.array(
    z.object({
      name: z.string(),
      description: optionalWithNull(z.string()),
      index: z.number(),
      addedOn: z.string(),
    }),
  ),
  waitingListPrice: optionalWithNull(z.number()),
  waitingListPriceEnabled: z.boolean(),
  slug: z.string(),
  cprNumber: optionalWithNull(z.string()),
});

export const waitingListEntryForUserArraySchema = z.array(waitingListEntryForUserSchema);

export type WaitingListEntryForUser = z.infer<typeof waitingListEntryForUserSchema>;
export type WaitingListEntriesForUserApiResponse = z.infer<ReturnType<typeof apiResponseSchema<typeof waitingListEntryForUserArraySchema>>>;
